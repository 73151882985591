<template>
  <section v-if="item.fichiers && item.fichiers.data">
    <b-container class="dernieres-publications">
      <h2><slot name="titre">Fichiers joints</slot></h2>
      <section v-for="fichier in item.fichiers.data" :key="fichier.id">
        <b-link :href="fichierHref(fichier)" target="_blank" @click.stop>
          <b-icon-download></b-icon-download>
          {{ fichier.attributes.name }}
        </b-link>
      </section>
    </b-container>
  </section>
</template>

<script>
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiFichiersComponent",
  props: ["item"],
  methods: {
    fichierHref(fichier) {
      return StrapiService.getAssetUrl(fichier.attributes.url);
    },
  },
};
</script>
