<template>
  <b-container fluid class="mt-3">
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <b-img
              center
              class="banniere mb-3"
              :src="couvertureSrc"
              :alt="couvertureAlt"
            ></b-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h1>{{ item.attributes.titre }}</h1>
            <p>
              <b-icon-calendar></b-icon-calendar>
              Le {{ datePublication }}
            </p>
            <p>
              <em>{{ item.attributes.description }}</em>
            </p>
            <StrapiMarkdownComponent :item="item.attributes.contenu">
            </StrapiMarkdownComponent>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="auto" class="mt-3 mt-md-0">
        <StrapiFichiersComponent :item="item.attributes.fichiers">
        </StrapiFichiersComponent>
        <b-container v-if="items.length > 0" class="dernieres-publications">
          <h2>Derni&egrave;res actualit&eacute;s</h2>
          <ul>
            <li v-for="autre in items" :key="autre.id">
              <b-link :to="{ name: 'actualite', params: { id: autre.id } }">
                {{ autre.attributes.titre }}
              </b-link>
            </li>
          </ul>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <StrapiCarrouselComponent :item="item.attributes.gallerie">
        </StrapiCarrouselComponent>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import StrapiMarkdownComponent from "./StrapiMarkdownComponent.vue";
import StrapiCarrouselComponent from "./StrapiCarrouselComponent.vue";
import StrapiFichiersComponent from "./StrapiFichiersComponent.vue";
import { actualite } from "../../services/strapi.service";
export default {
  name: "StrapiActualiteComponent",
  props: ["item", "items"],
  components: {
    StrapiMarkdownComponent,
    StrapiCarrouselComponent,
    StrapiFichiersComponent,
  },
  computed: {
    ...actualite.computed,
  },
};
</script>
